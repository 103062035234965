type Props = Readonly<{
  heading: string;
  subheading: string;
  primaryBtn: string;
  primaryBtnUrl: string;
  primaryExternal?: boolean;
  secondaryBtn?: string;
  secondaryBtnUrl?: string;
  secondaryExternal?: boolean;
  className?: string;
}>;

export function CTAPrimary({
  heading,
  subheading,
  primaryBtn,
  primaryBtnUrl,
  primaryExternal,
  secondaryBtn,
  secondaryBtnUrl,
  secondaryExternal,
  className,
}: Props): JSX.Element {
  return (
    <section className="section-sub">
      <div
        className={`container lg:flex items-center justify-between ${className}`}
      >
        <div>
          <h2 className="text-5xl font-semibold mt-0 mb-6">{heading}</h2>
          <p className="subheading m-0 mb-12 lg:mb-0">{subheading}</p>
        </div>
        <div className="flex gap-4">
          <a
            href={primaryBtnUrl}
            className="btn primary no-underline"
            {...(primaryExternal
              ? { rel: "noreferrer", target: "_blank" }
              : {})}
          >
            {primaryBtn}
          </a>
          {secondaryBtn && (
            <a
              href={secondaryBtnUrl}
              className="btn secondary no-underline !inline-block"
              {...(secondaryExternal
                ? { rel: "noreferrer", target: "_blank" }
                : {})}
            >
              {secondaryBtn}
            </a>
          )}
        </div>
      </div>
    </section>
  );
}
